import React from 'react';
import Sticky from 'react-stickynode';

import {Modal} from '@redq/reuse-modal';
import {ResetCSS} from '../common/assets/css/style';
import Navbar from '../containers/AppModern/Navbar';
import Banner from '../containers/AppModern/Banner';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {AppWrapper, ContentWrapper} from '../containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from '../components/seo';
import PageContent from '../components/content/PageContent';
import EveryPageWrapper from '../components/EveryPageWrapper';

export default function () {
	return (
		<EveryPageWrapper>
			{({domainTitle}) => (
				<>
					<SEO title="Community Guidelines" />
					<Modal />
					<ResetCSS />
					<GlobalStyle />

					<AppWrapper>
						<Sticky top={0} innerZ={9999} activeClass="sticky-active">
							<Navbar />
						</Sticky>
						<ContentWrapper>
							<Banner />
							<PageContent title={`${domainTitle} Community guidelines`}>
								<h3>The short version</h3>
								<p>
									We want Jump.in to be a place for people to enrich each other's lives through participation in safe, fun and interesting
									activities. Help us to encourage these values and build this community. Post only your own photos and videos and always
									follow the law. Respect everyone on Jump in, never risk your own or anyone else’s safety, and don’t spam people or post
									nudity. Be responsible.
								</p>
								<h3>The details</h3>
								<h4>Safety first</h4>
								<p>
									Our highest priority is to safeguard the personal wellbeing and safety of every member of our community. It is for this
									reason that we individually review and moderate every challenge or activity that is submitted before it is made available
									for others to participate in.
								</p>
								<p>
									Always consider your own safety before participating in any activity listed on the Jump platform, and protect others by
									reporting anything you see on the platform that you think may put someone at risk.
								</p>
								<p>
									By using Jump in, you agree to these guidelines and our <a href="https://jump.in/legal/terms">Terms of Use</a>. We’re
									committed to these guidelines and we hope you are too. Overstepping these boundaries may result in deleted content,
									disabled accounts or other restrictions.
								</p>
								<ul>
									<li>
										Use only photos and videos that you’ve taken yourself or have the right to share.
										<br />
										As always, you own the content you post on Jump in. Remember to post authentic content, and don’t post anything you’ve
										copied or collected from the Internet that you don’t have the right to post.
									</li>
									<li>
										Post challenges and content that are appropriate for a diverse audience.
										<br />
										We know that there are times when people might want to create challenges that are adult in nature, or include nude
										images that are artistic or creative, but for a variety of reasons, we don’t allow nudity or adult content on Jump in.
										This includes anything that may be perceived to have a suggestive or sexual theme, as well as photos, videos, and some
										digitally-created content that show sexual intercourse, genitals, and close-ups of fully-nude buttocks. It also includes
										some photos of female nipples, but photos of post-mastectomy scarring and women actively breastfeeding are allowed.
										Nudity in photos of paintings and sculptures is OK, too.
										<br />
										For safety reasons, there are times when we may remove content that shows nude or partially-nude children. Even when
										this content is shared with good intentions, it could be used by others in unanticipated ways.
									</li>
									<li>
										Foster meaningful and genuine interactions.
										<br />
										Help us stay spam-free by not artificially collecting likes, followers, or shares, posting repetitive comments or
										content, or repeatedly contacting people for commercial purposes without their consent. Don’t post content that engages
										in, promotes, encourages, facilitates, or admits to the offering, solicitation and trade of fake user reviews.
										<br />
										You don’t have to use your real name on Jump in, but we do require Jump in users to provide us with accurate and up to
										date information and at least one other social account for access to full features. Don't impersonate others and don't
										create accounts for the purpose of violating our guidelines or misleading others.
									</li>
									<li>
										Follow the law.
										<br />
										Jump in is not a place to support or praise terrorism, organized crime, or hate groups. Offering sexual services, buying
										or selling firearms, alcohol, and tobacco products between private individuals, and buying or selling illegal or
										prescription drugs (even if legal in your region) are also not allowed. Jump in also prohibits the sale of live animals
										between private individuals, though brick-and-mortar stores may offer these sales. No one may coordinate poaching or
										selling of endangered species or their parts.
										<br />
										Remember to always follow the law when offering to sell or buy other regulated goods. Accounts promoting online
										gambling, online real money games of skill or online lotteries must get our prior written permission before using any of
										our products.
										<br />
										We have zero tolerance when it comes to sharing sexual content involving minors or threatening to post intimate images
										of others.
									</li>
									<li>
										Respect other members of the Jump in community.
										<br />
										We want to foster a positive, diverse community. We remove content that contains credible threats or hate speech,
										content that targets private individuals to degrade or shame them, personal information meant to blackmail or harass
										someone, and repeated unwanted messages. We do generally allow stronger conversation around people who are featured in
										the news or have a large public audience due to their profession or chosen activities.
										<br />
										It's never OK to encourage violence or attack anyone based on their race, ethnicity, national origin, sex, gender,
										gender identity, sexual orientation, religious affiliation, disabilities, or diseases. When hate speech is being shared
										to challenge it or to raise awareness, we may allow it. In those instances, we ask that you express your intent clearly.
										<br />
										Serious threats of harm to public and personal safety aren't allowed. This includes specific threats of physical harm as
										well as threats of theft, vandalism, and other financial harm, or intentionally luring others to a location that may
										present any sort of danger. We carefully review reports of threats and consider many things when determining whether a
										threat is credible.
									</li>
									<li>
										Maintain our supportive environment by not glorifying self-injury.
										<br />
										The Jump in community cares for each other, and is often a place where people facing difficult issues such as eating
										disorders, cutting, or other kinds of self-injury come together to create awareness or find support. There are many
										existing sources available online for providing education in this area so people can get the help they need.
										<br />
										Encouraging or urging people to embrace self-injury is counter to this environment of support, and we’ll remove it or
										disable accounts if it’s reported to us. We may also remove content identifying victims or survivors of self-injury if
										the content targets them for attack or humor.
									</li>
									<li>
										Be thoughtful when posting newsworthy events.
										<br />
										We understand that many people use Jump in to share important and newsworthy events. Some of these issues can involve
										graphic images. Because so many different people and age groups use Jump in, we may remove content that shows intense,
										graphic violence to make sure Jump in stays appropriate for everyone.
										<br />
										We understand that people often share this kind of content to condemn, raise awareness or educate. If you do share
										content for these reasons, we encourage you to caption your content with a warning about graphic violence. Sharing
										graphic images for sadistic pleasure or to glorify violence is never allowed.
									</li>
								</ul>
								<h4>Help us keep the community strong:</h4>
								<ul>
									<li>
										Each of us is an important part of the Jump in community. If you see something that you think may violate our
										guidelines, please help us by using the reporting options that can be found on every item that is posted in the app. We
										have a global team that reviews these reports and works as quickly as possible to remove content that doesn’t meet our
										guidelines. Even if you or someone you know doesn’t have a Jump in account, you can still{' '}
										<a href="http://jump.in/contact">file a report</a>. When you complete the report, try to provide as much information as
										possible, such as links, usernames, and descriptions of the content, so we can find and review it quickly. We may remove
										entire posts if the activity, imagery or associated captions violate our guidelines.
									</li>
									<li>
										You may find challenges and content you don’t like, but doesn’t violate the Community Guidelines. If that happens, you
										can unfollow or block the person who posted it.
									</li>
									<li>
										Many disputes and misunderstandings can be resolved directly between members of the community. If one of your photos or
										videos was posted by someone else, you could try commenting on the post and asking the person to take it down. If that
										doesn’t work, you can <a href="http://jump.in/contact">file a report</a>. If you believe someone is violating your
										trademark, you can also <a href="http://jump.in/contact">file a report</a>. Don't target the person who posted it by
										posting screenshots and drawing attention to the situation because that may be classified as harassment.
									</li>
									<li>
										We may work with law enforcement, including when we believe that there’s risk of physical harm or threat to public
										safety.
									</li>
								</ul>
								<p>
									For more information, check out our <a href="http://help.jump.in">Help Center</a>&nbsp;and{' '}
									<a href="http://jump.in/legal/terms">Terms of Use</a>.
								</p>
								<p>Thank you for helping us create one of the most fun and exciting communities in the world,</p>
								<p>The Jump in Team</p>
								<h3>Jump.in Disclaimer</h3>
								<p>Challenge participants assume all risks involved in pursuing a challenge.</p>
								<p>&nbsp;</p>
								<p>
									Jump.in is owned and operated by Jump Corporation Pty Ltd. Information stored in the Jump.in platform is updated
									regularly. Neither Jump Corporation Pty Ltd, nor any agent, officer, employee or volunteer administrator of Jump
									Corporation Pty Ltd. warrants the accuracy, reliability, or timeliness of any information and shall not be liable for any
									losses caused by such reliance on the accuracy, reliability, or timeliness of such information. While every effort is made
									to ensure the accuracy of this information, portions may be incorrect or not current. Any person or entity that relies on
									information obtained from Jump.in does so at his or her own risk.
								</p>
								<p>&nbsp;</p>
								<p>
									Orienteering, hiking, backpacking and other outdoor activities involve risk to both persons and property. There are many
									variables including, but not limited to, weather, fitness level, terrain features and outdoor experience, that must be
									considered prior to creating or participating in any Jump.in challenge. Be prepared for your journey and be sure to check
									the current weather and conditions before heading outdoors. Always exercise common sense and caution. In no way shall Jump
									Corporation Pty Ltd nor any agent, officer, employee or volunteer administrator of Jump Corporation Pty Ltd, be liable for
									any direct, indirect, punitive, or consequential damages arising out of, or in any way connected with the use of this
									platform or use of the information contained within.
								</p>
								<p>&nbsp;</p>
								<p>
									The Jump.in website is for personal and non-commercial use. You may not modify, copy, excerpt, distribute, transmit,
									publish, license, create derivative works from, or sell any information, or services obtained from this website.
								</p>
								<p>&nbsp;</p>
								<p>
									Other products and companies referred to herein are trademarks or registered trademarks of their respective companies or
									mark holders.
								</p>
								<p>&nbsp;</p>
								<p>
									Individual challenges and activities are owned by the person(s) who physically placed the challenge and/or submitted the
									challenge listing to Jump.in
								</p>
								<p>&nbsp;</p>
								<p>
									Jump Corporation Pty Ltd reserves the right to change the terms, conditions, and notices under which this platform is
									offered.
								</p>
							</PageContent>
						</ContentWrapper>
						<Footer />
					</AppWrapper>
				</>
			)}
		</EveryPageWrapper>
	);
}
